import React, { useMemo } from "react"
import moment from "moment";
import { useLocation } from '@reach/router';

import Layout from '../components/common/Layout';
import { ProSectionContainer } from '../components/common/ProSectionContainer';
import './style.css';


const Author = ({ getAuthor, date = null }) => {
   return (
      <div className="flex-grow overflow-hidden">
         <div className="mt-3 flex items-center justify-center ">
            <div className="flex-shrink-0">
               <span className="sr-only">Hethal Solanki</span>
               <img
                  alt=""
                  loading="lazy"
                  width={40}
                  height={40}
                  decoding="async"
                  data-nimg={1}
                  className="inline-block rounded-full "
                  src="https://previews.123rf.com/images/archivector/archivector1902/archivector190200317/117964202-abstract-sign-avatar-men-icon-male-profile-white-symbol-on-gray-circle-background-vector.jpg"
                  style={{ color: "transparent" }}
               />
            </div>
            <div className="ml-3">
               <p className="text-sm font-medium mb-0 text-xm-default dark:text-xm-default-50">
                  {getAuthor}
               </p>
               <div className="flex space-x-1 text-sm text-gray-500 dark:text-xm-default-300">
                  <time dateTime="2024-07-17">
                     {date && (
                        moment(date).format('llll')
                     )}
                  </time>
               </div>
            </div>
         </div>
      </div>
   )
}

const PostTemplate = (props) => {

   const { state } = useLocation();

   const { title, ...item } = props.pageContext;

   const getAuthor = useMemo(() => {
      if (!item?.author?.node) return null;

      const { firstName, lastName, name, email } = item?.author?.node;
      return firstName ?? lastName ?? name ?? email;

   }, [item])

   const styleSheets = state?.stylesheets ? useMemo(() => {
      const { stylesheets } = state;
      return stylesheets;
   }, [state?.stylesheets]) : []

   console.log(styleSheets)

   return (
      <Layout>
         <div className="layout banner_spacing_md">
            <p className="flex flex-row flex-wrap items-center justify-center gap-2">
               {item?.tags?.edges?.map((tag, index) => {
                  return (
                     <span
                        key={index}
                        className="inline-flex items-center rounded-full bg-primary-100/20 px-3 py-0.5 text-xs font-medium leading-5 text-primary-200 "
                     >
                        {tag?.node?.name}
                     </span>
                  )
               })}
            </p>
            <ProSectionContainer
               title={title}
               nodeType="html"
               className="!p-0 mt-7"
               extraNodes={
                  <div>
                     <Author getAuthor={getAuthor} date={item?.date} />
                     <div
                        className="layout mt-10 leading-8 [&>strong]:mb-2"
                        dangerouslySetInnerHTML={{ __html: item?.content ?? '' }}
                     />
                  </div>
               }
            />
         </div>
      </Layout>
   )
}

export default PostTemplate;